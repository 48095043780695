import(/* webpackMode: "eager", webpackExports: ["default"] */ "/repos/loxinformatics/custom/About/About.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/repos/loxinformatics/lox/layout/Contact/Contact.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/repos/loxinformatics/lox/layout/Footer/Footer.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/repos/loxinformatics/lox/layout/Header/Header.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/repos/loxinformatics/lox/layout/Hero/Hero.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/repos/loxinformatics/lox/layout/Services/Services.jsx");
